module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/codebuild/output/src362702145/src/www.shotwire.com/node_modules/gatsby-remark-images","id":"a7b48671-0d36-5360-aa88-fe29d22faef2","name":"gatsby-remark-images","version":"3.3.25","pluginOptions":{"plugins":[],"maxWidth":1920,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent"},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".md",".mdx"],"defaultLayouts":{"default":"/codebuild/output/src362702145/src/www.shotwire.com/node_modules/gatsby-theme-catalyst-core/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-relative-images","options":{"name":"images"}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1920,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent"}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"content/assets"}},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-reading-time"},{"resolve":"gatsby-remark-responsive-iframe"},{"resolve":"gatsby-remark-external-links"}],"remarkPlugins":[null],"rehypePlugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1920,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent"},
    },{
      plugin: require('../node_modules/@pauliescanlon/gatsby-mdx-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-catalyst-core/gatsby-browser.js'),
      options: {"plugins":[],"displaySiteLogo":false,"displaySiteLogoMobile":false,"useStickyHeader":true,"useSocialLinks":false,"remarkImagesWidth":1920},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-catalyst","short_name":"catalyst","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"content/assets/catalyst-site-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6b8daf2220a3ebcd4a9e0bebbe761ad7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-2671032-18","head":false,"anonymize":true},
    }]
